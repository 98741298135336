import { useI18n } from 'vue-i18n';

interface PriceFormatter {
    formatPrice(this: void, price: number): string;
}

const PRICE_FACTOR = 100;

export function usePriceFormatter(): PriceFormatter {
    const i18n = useI18n();

    return {
        formatPrice(price: number): string {
            const locale = i18n.locale.value;

            const currencySymbol =
                Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' })
                    .formatToParts(1)
                    .find((x) => x.type === 'currency')?.value ?? '';

            const formatter = Intl.NumberFormat(locale, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            return `${currencySymbol} ${formatter.format(price / PRICE_FACTOR)}`;
        },
    };
}
